<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 col-md-2">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              hide-details
              v-model="startDateFormatted"
              label="От"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            locale="ru-RU"
            first-day-of-week="1"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="col-12 col-md-2">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              hide-details
              v-model="endDateFormatted"
              label="До"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              @blur="endDate"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            locale="ru-RU"
            first-day-of-week="1"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="col-12 col-md-2"
        ><v-select
          hide-details
          v-model="point_filter"
          :items="point_numbers"
          item-text="address"
          item-value="point_number"
          label="Станция"
          clearable
        ></v-select
      ></v-col>
      <v-col class="col-12 col-md-2"
        ><v-select
          hide-details
          v-model="status_filter"
          :items="available_status"
          label="Статус"
          clearable
        ></v-select
      ></v-col>
      <v-col class="col-12 col-md-2"
        ><v-text-field
          hide-details
          label="Номер клиента"
          v-model="client_filter"
          clearable
        ></v-text-field
      ></v-col>
      <v-col class="col-12 col-md-1"
        ><v-text-field
          hide-details
          v-model="payment_filter"
          label="Оплата"
          clearable
        ></v-text-field
      ></v-col>
      <v-col class="col-12 col-md-1">
        <v-switch hide-details v-model="rfcard" label="RFcard"></v-switch>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="users_headers"
          :items="charges"
          :options.sync="options"
          :server-items-length="total_charges"
          :multi-sort="false"
          item-key="id"
          :loading="loading_charges"
          loading-text="Загрузка... Подождите"
          no-data-text="Зарядные сессии не найдены"
          :items-per-page="25"
          @click:row="charge_info"
          :item-class="row_class"
          :footer-props="{
            'items-per-page-options': [15, 25, 50, 100, -1],
            showFirstLastPage: true,
            'items-per-page-text': 'Всего на странице',
          }"
        >
          <template v-slot:item.status="{ item }">
            {{ charging_status(item.status) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ChargingList",
  data() {
    return {
      polling: null,
      client_filter: null,
      status_filter: null,
      point_filter: null,
      menu: false,
      menu2: false,
      startDate: null,
      endDate: null,
      payment_filter: null,
      rfcard: false,
      dateFormatted: null,
      startDateFormatted: null,
      endDateFormatted: null,
      options: {
        sortBy: ["id"],
        sortDesc: [true],
        page: 1,
      },
    };
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  created() {
    if (localStorage.getItem("charges_filters")) {
      let params = JSON.parse(localStorage.getItem("charges_filters"));
      this.client_filter = params.client ? params.client : null;
      this.status_filter = params.status ? params.status : null;
      this.point_filter = params.point ? params.point : null;
      this.startDate = params.startDate
        ? params.startDate
        : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10);
      this.endDate = params.endDate
        ? params.endDate
        : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10);
      this.payment_filter = params.payment ? params.payment : null;
      this.rfcard = params.rfcard ? params.rfcard : false;
      this.options.sortBy = params.sortBy ? params.sortBy : ["id"];
      this.options.sortDesc = params.sortDesc ? params.sortDesc : [false];
      this.options.page = params.page ? params.page : 1;
    } else {
      this.startDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.endDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
    }
    //   this.load_params();
    // }
    this.load_charges();
    this.pollData();
  },
  watch: {
    params: {
      handler() {
        this.load_charges();
      },
      deep: true,
    },
    options: {
      handler() {
        this.load_charges();
      },
      deep: true,
    },
    startDate() {
      this.startDateFormatted = this.formatDate(this.startDate);
      if (this.startDate > this.endDate) {
        this.endDate = this.startDate;
      }
    },
    endDate() {
      if (this.endDate < this.startDate) {
        this.endDate = this.startDate;
      }
      this.endDateFormatted = this.formatDate(this.endDate);
    },
  },
  computed: {
    params() {
      return {
        ...this.options,
        startDate: this.startDate,
        endDate: this.endDate,
        client: this.client_filter,
        status: this.status_filter,
        payment: this.payment_filter,
        rfcard: this.rfcard,
        point: this.point_filter,
      };
    },
    loading_charges() {
      return this.$store.getters.LOADING_CHARGES;
    },
    total_charges() {
      return this.$store.getters.TOTAL_CHARGES;
    },
    users_headers() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          width: "80",
        },
        {
          text: "Станция",
          align: "start",
          sortable: true,
          value: "charge_point",
        },
        {
          text: "Коннектор",
          align: "start",
          sortable: true,
          value: "connector",
        },
        {
          text: "Передано",
          align: "start",
          sortable: true,
          value: "power_amount",
        },
        {
          text: "Оплата по тарифу",
          align: "start",
          sortable: true,
          value: "real_payment",
        },
        {
          text: "Оплата конечная",
          align: "start",
          sortable: true,
          value: "payment_amount",
        },
        {
          text: "Оплата реальная",
          align: "start",
          sortable: true,
          value: "real_payment",
        },
        {
          text: "Клиент",
          align: "start",
          sortable: true,
          value: "client",
        },
        {
          text: "Статус",
          align: "start",
          sortable: true,
          value: "status",
        },
        {
          text: "Начало",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Окончание",
          align: "start",
          sortable: true,
          value: "updated_at",
        },
      ];
    },
    charges() {
      return this.$store.getters.CHARGES;
    },
    point_numbers() {
      return this.$store.getters.POINT_NUMBERS;
    },
    available_status() {
      return [
        {
          text: "Бронирование",
          value: "reserved",
        },
        {
          text: "Идет зарядка",
          value: "charging",
        },
        {
          text: "Зарядка завершена",
          value: "end",
        },
        {
          text: "Оплачено",
          value: "paid",
        },
        {
          text: "Ожидание оплаты",
          value: "waiting_payment",
        },
        {
          text: "Ожидание подключения",
          value: "preparing",
        },
        {
          text: "Прервана/Неоплачена",
          value: "interrupted",
        },
      ];
    },
  },
  methods: {
    pollData() {
      this.polling = setInterval(() => {
        this.load_charges();
      }, 20000);
    },
    compare_dates() {
      if (this.startDate !== null && this.endDate !== null) {
        if (this.startDate > this.endDate) {
          this.endDate = this.startDate;
        }
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");

      return `${day}-${month}-${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("-");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    load_params() {
      this.startDate =
        this.$route.query?.startDate !== undefined
          ? this.$route.query.startDate
          : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10);

      this.endDate =
        this.$route.query?.endDate !== undefined
          ? this.$route.query.endDate
          : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10);
      this.status_filter =
        this.$route.query?.status !== undefined
          ? this.$route.query.status
          : null;
      this.client_filter =
        this.$route.query?.client !== undefined
          ? this.$route.query.client
          : null;
      this.payment_filter =
        this.$route.query?.payment !== undefined
          ? this.$route.query.payment
          : null;
    },
    load_charges() {
      this.$store.dispatch("list_charges", this.params);
    },
    charging_status(status_code) {
      switch (status_code) {
        case "reserved":
          return "Забронирована";
        case "charging":
          return "Идет зарядка";
        case "end":
          return "Зарядка завершена";
        case "interrupted":
          return "Зарядка прервана";
        case "paid":
          return "Оплачено";
        case "waiting_payment":
          return "Ожидание оплаты";
        case "preparing":
          return "Ожидание подключения";
      }
    },
    charge_info(item) {
      this.$router.push({
        name: "charging_info",
        params: { charge_id: item.id },
      });
    },
    row_class() {
      return "clickable";
    },
    insertUrlParam(key, value) {
      if (history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, value);
        let newurl =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          "?" +
          searchParams.toString();
        window.history.pushState({ path: newurl }, "", newurl);
      }
    },

    removeUrlParameter(paramKey) {
      const url = window.location.href;
      var r = new URL(url);
      r.searchParams.delete(paramKey);
      const newUrl = r.href;
      window.history.pushState({ path: newUrl }, "", newUrl);
    },
  },
};
</script>

<style scoped></style>
